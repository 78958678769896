<!-- This Vue component is the patient list selector. -->
<template>
    <b-modal
        id="QuickPatientSelectionModal"
        :visible="modalShow"
        dialog-class="quick-selector-position"
        content-class="rounded-0"
        body-class="p-0"
        header-class="py-1"
        size="lg"
        @hide="hideSelector"
        scrollable
        hide-footer
    >
        <b-form @submit.prevent="searchChanged" inline class="px-3 pb-3">
            <label class="sr-only" for="inline-form-input-name">{{ t('search') }}</label>

            <b-input-group class="col mt-2 px-0">
                <b-input-group-prepend is-text>
                    <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                    type="search"
                    v-model="queryParams.search"
                    class="mr-2 mb-sm-0"
                    :placeholder="t('search')"
                ></b-form-input>
            </b-input-group>

            <b-button
                type="submit"
                class="mt-2 mr-sm-2 mb-sm-0 btn-block-xs-only"
                variant="primary"
                >{{ t('search') }}</b-button
            >

            <b-button
                v-if="
                    checkPermissions({
                        [this.PERMISSIONS.CREATE_PATIENT]: this.PERMISSIONS_VALUES.ENABLED,
                    })
                "
                class="mt-2 main-navigation-button btn-block-xs-only"
                variant="primary"
                to="/NewPatient"
            >
                <b-icon-plus /> {{ t('addNewPatient') }}
            </b-button>
        </b-form>

        <b-table
            no-local-sorting
            borderless
            :items="patientList"
            :fields="fields"
            :sort-desc="queryParams.sortOrder === 'desc'"
            :sort-by="queryParams.sortBy"
            :tbody-tr-class="trClasses"
            @sort-changed="sortChanged"
            sticky-header="85%"
            responsive="lg"
            class="quick-patient-selection-table"
            id="quick-patient-selection-table"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>

            <template #head(ocosPatientRef)>
                {{ t('patientId') }}
                <div class="mt-1 column-explanation-text">
                    {{ activeCustomer.hidePatientNames ? '&nbsp;' : t('patientNameLastFirst') }}
                </div>
            </template>

            <template #head(dateOfBirth)>
                {{ t('dob') }}
                <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
            </template>

            <template #head(surgeon_name)>
                {{ t('surgeon') }}
                <div class="mt-1 column-explanation-text">{{ t('patientNameLastFirst') }}</div>
            </template>

            <template #cell(ocosPatientRef)="{item}">
                <custom-router-link
                    v-if="!isEmpty(item)"
                    :to="{
                        name: 'PatientDetails',
                        params: {patientId: item.patientId},
                    }"
                    @click.native="hideSelector"
                    class="patient-link text-primary-dark d-block mb-1 text-caption heavy"
                >
                    <u> {{ item.ocosPatientRef }}</u>
                </custom-router-link>
                <p
                    v-if="!activeCustomer.hidePatientNames"
                    class="text-caption light text-gray-dark mb-0"
                >
                    {{ namesFormatter(item.patientLastName, item.patientFirstName) }}
                </p>
            </template>

            <template #cell(dateOfBirth)="data">
                <span v-if="!isEmpty(data.item)" class="text-caption text-gray-dark">
                    {{ data.item.dateOfBirth | date({isUTC: false}) }}
                </span>
            </template>

            <template #cell(surgeon_name)="{item}">
                <div v-if="!isEmpty(item)" class="text-caption heavy d-flex align-items-start">
                    {{
                        surgeonIdNameFormat(
                            item.contactPartyNumber,
                            `${item.surgeonLastName}, ${item.surgeonFirstName}`,
                            item.doctorActive
                        )
                    }}
                </div>
            </template>
        </b-table>
        <SpinnerOverlay position="absolute" v-if="loading" />
    </b-modal>
</template>
<script>
import {mapGetters, mapActions} from 'vuex';
import {isEmpty} from 'lodash';
import SpinnerOverlay from '@/views/layout/SpinnerOverlay';
import {namesFormatter, surgeonIdNameFormat} from '@/utilities/formatters';

const defaultQueryParams = () => ({
    search: null,
    sortOrder: 'asc',
    sortBy: 'ocosPatientRef',
    currentPage: 1,
    perPage: 10,
});

export default {
    name: 'QuickPatientSelection',
    components: {
        SpinnerOverlay,
    },
    props: {
        modalShow: {
            default: false,
        },
    },
    data() {
        return {
            queryParams: defaultQueryParams(),
            fields: [
                {
                    key: 'ocosPatientRef',
                    stickyColumn: true,
                    tdClass: 'np bg-white',
                    sortable: true,
                },
                {
                    key: 'dateOfBirth',
                    label: 'DOB',
                    tdClass: 'np bg-white',
                    sortable: false,
                },
                {
                    key: 'surgeon_name',
                    label: 'Surgeon',
                    tdClass: 'np bg-white',
                    sortable: true,
                },
            ],
            colWidths: {
                ocosPatientRef: '250px',
                dateOfBirth: '175px',
                surgeon_name: '325px',
            },
        };
    },
    computed: {
        ...mapGetters('user', ['activeCustomer']),
        ...mapGetters({
            patientList: 'patientSearch/patientList',
            canLoadMore: 'patientSearch/canLoadMore',
            loading: 'patientSearch/loading',
        }),
    },
    watch: {
        modalShow(newValue) {
            this.$nextTick(() => {
                if (newValue) {
                    this.getNext();
                    this.getTableParentElement().addEventListener('scroll', this.getNext);
                } else {
                    this.queryParams = defaultQueryParams();
                    this.clearPatientList();
                    this.fetchPatients();
                    this.getTableParentElement().removeEventListener('scroll', this.getNext);
                }
            });
        },
    },
    methods: {
        isEmpty,
        namesFormatter,
        surgeonIdNameFormat,
        ...mapActions({
            fetchPatientList: 'patientSearch/fetchPatientList',
            clearPatientList: 'patientSearch/clearPatientList',
        }),
        trClasses(item) {
            return isEmpty(item) ? 'not-loaded' : 'loaded';
        },
        hideSelector() {
            this.$emit('update:modalShow', false);
        },
        getTableParentElement() {
            return document.querySelector('#quick-patient-selection-table').parentElement;
        },
        getNotLoadedTableBodyTrElements() {
            return [...document.querySelectorAll('tr.not-loaded')];
        },
        isNotLoadedElementScrolledIntoView() {
            try {
                const docViewTop = this.getTableParentElement().scrollTop;
                const docViewBottom = docViewTop + this.getTableParentElement().clientHeight;

                const isScrolledIntoView = this.getNotLoadedTableBodyTrElements().some(
                    (notLoadedElement) => {
                        const notLoadedElementTop = notLoadedElement.offsetTop;
                        const notLoadedElementBottom =
                            notLoadedElementTop + notLoadedElement.clientHeight;
                        return (
                            notLoadedElementBottom <= docViewBottom &&
                            notLoadedElementTop >= docViewTop
                        );
                    }
                );
                return isScrolledIntoView;
            } catch (ex) {
                return false;
            }
        },
        async getNext() {
            if (this.isNotLoadedElementScrolledIntoView() && this.canLoadMore) {
                this.queryParams.currentPage++;
                await this.fetchPatients();
            }
        },
        async sortChanged(context) {
            if (!context.sortBy) return;
            this.getTableParentElement().scrollTo(0, 0);
            this.queryParams.currentPage = 1;
            this.queryParams.sortBy = context.sortBy;
            this.queryParams.sortOrder = context.sortDesc ? 'desc' : 'asc';
            await this.fetchPatients();
        },
        async searchChanged() {
            this.getTableParentElement().scrollTo(0, 0);
            if (!this.queryParams.search) this.queryParams = defaultQueryParams();
            this.queryParams.currentPage = 1;
            await this.fetchPatients();
        },
        async fetchPatients() {
            await this.fetchPatientList(this.queryParams);
            await this.getNext();
        },
    },
    async mounted() {
        await this.fetchPatients();
    },
};
</script>
<style lang="scss" scoped>
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

@include media-breakpoint-up(lg) {
    ::v-deep .quick-selector-position {
        position: absolute;
        left: 5%;
        top: 8%;
    }
}

@media (min-width: 576px) {
    ::v-deep .modal-dialog-scrollable {
        max-height: calc(100% - 7.5rem);
    }
}

::v-deep .quick-selector-position {
    max-width: 800px;
    padding-left: 5px;
    padding-right: 5px;
}

::v-deep .not-loaded {
    height: 40px;
}

::v-deep .quick-patient-selection-table > table {
    min-width: 500px;
}

.column-explanation-text {
    font-size: 0.57rem;
    font-weight: 400px;
}
</style>
